import { Card, Layout } from 'antd';
import type { NormalProgramme, MainSubStructureModel } from 'egenie-utils';
import { MainSubStructure, NormalProgrammeComponent } from 'egenie-utils';
import React from 'react';
import { getWarehouse, getWarehouseArea } from '../../utils';
import styles from './index.less';
import { mainSubStructureModel } from './mainSubStructureModel';
import { normalProgramme } from './normalProgramme';

export class Store {
  constructor() {
    this.mainSubStructureModel = mainSubStructureModel(this);

    this.normalProgramme = normalProgramme(this);

    this.mainSubStructureModel.getFilterParams = () => this.normalProgramme.filterItems.params;

    getWarehouse()
      .then((data) => this.normalProgramme.filterItems.addDict({ warehouseId: data }));
    getWarehouseArea()
      .then((data) => this.normalProgramme.filterItems.addDict({ warehouseAreaIds: data }));
  }

  public mainSubStructureModel: MainSubStructureModel;

  public normalProgramme: NormalProgramme;
}

export default class extends React.Component {
  public store = new Store();

  render() {
    return (
      <Layout className={styles.container}>
        <Layout.Content>
          <Card size="small">
            <NormalProgrammeComponent store={this.store.normalProgramme}/>
          </Card>
          <div className={styles.tableWrapper}>
            <MainSubStructure store={this.store.mainSubStructureModel}/>
          </div>
        </Layout.Content>
      </Layout>
    );
  }
}
