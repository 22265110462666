import type { FilterSelect } from 'egenie-utils';
import { NormalProgramme } from 'egenie-utils';
import { getWarehouseArea } from '../../utils';
import type { Store } from './index';

export function normalProgramme(context: Store): NormalProgramme {
  return new NormalProgramme({
    filterItems: [
      {
        type: 'dateRange',
        field: 'dateValue',
        label: '日期',
      },
      {
        type: 'select',
        field: 'warehouseId',
        label: '仓库',
        onChangeCallback: (warehouseId: string) => {
          const warehouseAreaIdItem = context.normalProgramme.filterItems.getFilterItem('warehouseAreaIds') as FilterSelect;
          warehouseAreaIdItem.value = [];
          warehouseAreaIdItem.handleData([]);

          getWarehouseArea({ warehouseId })
            .then((data) => warehouseAreaIdItem.handleData(data));
        },
      },
      {
        type: 'select',
        field: 'warehouseAreaIds',
        mode: 'multiple',
        label: '库区',
      },
      {
        type: 'input',
        field: 'skuNo',
        label: 'SKU编码',
      },
    ],
    count: 6,
    handleSearch: () => context.mainSubStructureModel.onQuery(),
  });
}
