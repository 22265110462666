import type { PaginationData } from 'egenie-utils';
import { TimeStampFormatter, ImgFormatter, MainSubStructureModel, request } from 'egenie-utils';
import React from 'react';
import type { MainItem } from './types';
import type { Store } from './index';

let id = 0;

export function mainSubStructureModel(context: Store): MainSubStructureModel {
  return new MainSubStructureModel({
    grid: {
      columns: [
        {
          key: 'pic',
          name: '图片',
          width: 50,
          formatter: ({ row }) => <ImgFormatter value={row.pic}/>,
        },
        {
          key: 'productNo',
          name: '商品编码',
          width: 250,
        },
        {
          key: 'skuNo',
          name: 'SKU编码',
          width: 250,
        },
        {
          key: 'color',
          name: '颜色',
          width: 100,
        },
        {
          key: 'size',
          name: '尺寸',
          width: 100,
        },
        {
          key: 'warehouseName',
          name: '仓库',
          width: 120,
        },
        {
          key: 'sourceNo',
          name: '单据编号',
          width: 250,
        },
        {
          key: 'operationTypeName',
          name: '操作类型',
          width: 100,
        },
        {
          key: 'createTime',
          name: '操作时间',
          width: 150,
          formatter: ({ row }) => <TimeStampFormatter value={row.createTime}/>,
        },
        {
          key: 'operator',
          name: '操作人',
          width: 100,
        },
        {
          key: 'entityNum',
          name: '实物变化量',
          width: 120,
        },
        {
          key: 'resultNum',
          name: '结余库存',
          width: 120,
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: '_id',
      sortByLocal: false,
      showCheckBox: false,
      showEmpty: true,
      pageSize: 50,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/inventoryFullLink/index',
    },

    api: {
      onQuery: (params) => {
        const {
          filterParams = {},
          ...rest
        } = params;

        return request<PaginationData<MainItem>>({
          url: '/api/cloud/wms/rest/report/stock/queryStockAllTraceReport',
          method: 'POST',
          data: {
            ...rest,
            ...filterParams,
          },
        })
          .then((info) => ({
            status: 'Successful',
            data: {
              ...info.data,
              list: (info.data?.list || []).map((item) => ({
                ...item,
                _id: id++,
              })),
            },
          }));
      },
    },
    hiddenSubTable: true,
  });
}
